/*
 * @Description: 处理axios并封装一些方法
 * @Author: Ronda
 * @Date: 2021-06-29 15:00:11
 * @LastEditors: Ronda
 * @LastEditTime: 2021-06-29 16:01:10
 */
import axios from 'axios';
import qs from 'qs';
import { baseURL } from './config';
import { getToken, removeToken } from './auth'
import router from '@/router'
import { Notify } from 'vant';

const service = axios.create({
  timeout: 10000,
  baseURL
});

// 请求拦截器
service.interceptors.request.use(config => {
  // 拦住请求，给每个请求头部设置身份认证
  if (getToken()) {
    config.headers['Authorization'] = getToken();
  }
  return config;
}, error => {
  return Promise.reject(error);
});

// 响应拦截器
service.interceptors.response.use(response => {
  // 处理数据的拆分，res就是后台给的数据
  const { data: res } = response;
  // 状态判断
  if (res.code !== 200) {
    if (res.code === 500) {
      // 后台服务器异常
      Notify({ type: 'danger', message: res.msg });
    }
    if (res.code === 401) {
      // token异常
      removeToken();
      // 手动操作路由跳转到登录页
      router.replace({ path: '/login' })
    }
    // 弹框提示
    // alert('错误');
    return Promise.reject(new Error(res.msg || 'Error'));
  } else {
    return res;
  }
}, error => {
  // 弹框提示
  Notify({ type: 'danger', message: error.message });
  return Promise.reject(error);
});

// get请求
export function get(url, data) {
  return service.get(url, { params: data });
}
// post请求发送表单数据
export function post(url, data) {
  return service.post(url, qs.stringify(data));
}
// post请求发送json数据
export function postJSON(url, data) {
  return service.post(url, data);
}
// put请求
export function put(url, data) {
    return service.put(url, qs.stringify(data));
}

export default service;
















/*
 * @Description: cookie操作
 * @Author: Ronda
 * @Date: 2021-06-29 15:17:37
 * @LastEditors: Ronda
 * @LastEditTime: 2021-06-29 15:27:15
 */
import Cookies from 'js-cookie';
import { tokenKey } from './config'

// 设置token
export function setToken(token) {
    // 给cookie里存值，key-value形式
    Cookies.set(tokenKey, token);
}
// 获取token
export function getToken() {
    return Cookies.get(tokenKey);
}
// 移除token
export function removeToken() {
    sessionStorage.clear();
    Cookies.remove(tokenKey);
}
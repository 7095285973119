/*
 * @Description: 项目开发的配置文件
 */
// axios请求的基路径
// const baseURL = 'http://192.168.112.148:8080/gecs-api';
const baseURL = 'https://api.fengyechanye.com/gecs-api';
// const baseURL = 'http://localhost:8080/gecs-api';
// cookie里token的key值  symbol
const tokenKey = 'Authorization';
export {
  baseURL, tokenKey
}
